<template>
  <div class="">
    <ControlNavbar/>
    <div class="container">
      <div class="banner" v-if="voucher.banner">
        <Banner :banner="voucher.banner"/>
      </div>

      <div class="category row align-items-center">
        <div class="col-12">
          <br/>
          <img
              :src="voucher.data.image"
              :alt="voucher.data.icon"
              width="auto"
          />
          <p>{{ voucher.data.name }}</p>
          <a-form layout="inline" :form="form" @submit="handleSubmit">
            <a-form-item
                :label="voucher.data.quantity.label"
                v-if="voucher.data.quantity.visible"
            >
              <a-input-number
                  v-decorator="[
                  voucher.data.quantity.label,
                  { initialValue: voucher.data.quantity.default },
                ]"
                  :min="voucher.data.quantity.min"
                  :max="voucher.data.quantity.max"
              />
            </a-form-item>
            <a-form-item
                :label="voucher.data.price.label"
                v-if="voucher.data.price.visible"
            >
              <span class="ant-form-text">
                {{
                  voucher.data.price.price
                      | numeral($session.get("currencyFormat").replace("#", 0))
                }}
                {{ $session.get("currency") }}
              </span>
            </a-form-item>
            <a-form-item>
              <a-button
                  type="danger"
                  html-type="submit"
                  v-if="voucher.data.voucher"
              >
                {{ voucher.data.voucher.button }}
              </a-button>
              <a-button
                  type="danger"
                  v-if="voucher.data.topup"
                  @click="showDirectRechargeModal"
              >
                {{ voucher.data.topup.button }}
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
    <info-button v-if="voucher.data.help" :help="voucher.data.help"/>
    <a-modal
        class="directRecharge"
        v-model="directRechargeVisible"
        :title="voucher.data.topup.button"
        @ok="handleDirectRecharge"
        v-if="voucher.data.topup"
    >
      <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
        <a-form-item
            :label="voucher.data.topup.label"
            v-if="voucher.data.topup.inputType"
        >
          <a-input-number
              style="width: 100%"
              v-model="directRechargeField"
              :placeholder="voucher.data.topup.hint"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
        :destroyOnClose="true"
        v-model="visible"
        title="Voucher Info"
        @ok="
        $router.push({
          name: 'Home',
        })
      "
        v-if="voucherDetails"
    >
      <!--      <button @click="printPrint">{{ locale.print }}</button>-->
      <div id="voucherPrint">
        <div v-for="(v, i) in voucherDetails" :key="i">
          <div
              class="row"
              style="border-bottom: 2px dotted black; padding: 10%"
          >
            <div class="col-2">
              <img
                  class="voucher-result"
                  :src="v.pictureUrl"
                  :alt="v.picture"
                  width="inherit"
              />
            </div>
            <div class="col-8">
              <p>{{ v.denomination }}</p>
              <p>
                <strong>{{ locale.transaction_id }} </strong
                >{{ v.transactionId }}
              </p>
              <p>
                <strong>{{ locale.amount }} </strong>{{ v.amount }}
              </p>
              <p>
                <strong>{{ locale.sn }} </strong>{{ v.serial }}
              </p>
              <p>
                <strong>{{ locale.secret_code }} </strong
                >{{ v.formattedSecret }}
              </p>
              <p>
                <strong>{{ locale.expiry_date }} </strong>{{ v.expiry }}
              </p>
            </div>
            <div class="col-2">
              {{ v.time }}
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal
        v-model="paymentOptionsVisible"
        :title="locale.paymentoptions"
        @ok="paymentOptionsVisible = !paymentOptionsVisible"
        :footer="null"
    >
      <div v-if="showPaymentOptions">
        <div
            class="
            d-flex
            justify-content-between
            card_custom
            pointer
            align-items-center
          "
            v-for="option in $session.get('paymentOptions')"
            :key="option.id"
            @click="selectPaymentOption(option)"
        >
          <p>{{ option.label }}</p>
          <p>
            {{ voucher.data.prices[`${option.id}`] }}
          </p>
        </div>
      </div>

      <PaymentCC
          v-if="paymentOption == 'creditcard'"
          @options="
          ($event) => {
            this.showPaymentOptions = $event;
          }
        "
          :apiBody="apiBody"
          @paymentByCCSuccess="paymentByCCSuccess"
      />
    </a-modal>
    <loading :loading="loader"/>
  </div>
</template>
<script>
// @ is an alias to /src

import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import firebase from "firebase";
import ControlNavbar from "@/components/ControlNavbar";
import Banner from "@/components/Banner";
import PaymentCC from "@/components/PaymentCC";
import Loading from "./Loading.vue";
import {LocaleArabic, LocaleEnglish} from "@/scripts/Locale";

export default {
  name: "Voucher",
  components: {ControlNavbar, Banner, PaymentCC, Loading},
  data() {
    return {
      locale: null,
      loader: false, //used to start and stop the loader
      directRechargeField: null, //used to take the phone number and add it for the direct recharge submit
      voucherDetails: null,
      visible: false,
      directRechargeVisible: false,
      form: this.$form.createForm(this, {name: "voucher_form"}),
      voucher: null,
      voucherId: null,

      // credit card payment
      paymentOptionsVisible: false,
      paymentOption: null,
      apiBody: null,
      showPaymentOptions: true,
      directRechargeFlag: false, // used to know if it's a direct recharge or not in order to know if payment by whish recharge or whish get order
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") === "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
    setLoader(show) {
      this.loader = show;
    },
    printPrint() {
      // Pass the element id here
      // this.$htmlToPaper("voucherPrint");
      // document
      //   .getElementById("voucherPrint")
      //   .prepend(
      //     `<div><h1 ><center><img src="${require("../assets/whish-white-logo_v2.png")}" style="width: 100px; margin-bottom:5px;filter: invert(1); "/></center></h1>`
      //   );
      let prtHtml = document.getElementById("voucherPrint").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
          "",
          "",
          "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(
          `<!DOCTYPE html>
          <html>
            <head>
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css">
              ${stylesHtml}
            </head>
            <body>

              ${prtHtml}
            </body>
          </html>`
      );

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();
    },
    success(title, message) {
      this.$success({
        title: title,
        // JSX support
        content: (
            <div>
              <p>{message}</p>
            </div>
        ),
      });
    },
    selectPaymentOption(payOption) {
      // when clicked on the price fields of payment options if credit card open credit card commponent and give it the values that it needs to send to the api
      if (payOption.type == "creditcard") {
        this.form.validateFields((err, values) => {
          if (!err) {
            values.type = this.voucher.type;
            values.fetchPrice = true;
            values.denominationId = this.voucherId;
            values.numberOfItems = values.Quantity;
            delete values.Quantity;
            // this.checkPaymentMethod();
            if (this.directRechargeField) {
              values.recipientMsisdn = this.directRechargeField;
            }
            values.optionId = payOption.id;
            this.apiBody = values;
            this.apiBody.type = this.voucher.type;
            this.paymentOption = payOption.type;

            console.log("Received values of form: ", values);
          }
        });
      } else {
        this.paymentOptionsVisible = false;
        if (this.directRechargeFlag) {
          // sale recharge
          this.whishDirectRecharge(payOption.id);
        } else {
          // sale voucher
          console.log("whsh get order");
          this.whishGetOrder(payOption.id);
        }
      }
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    showModal() {
      this.visible = true;
    },
    showDirectRechargeModal() {
      this.directRechargeVisible = true;
    },
    hideDirectRechargeModal() {
      this.directRechargeVisible = false;
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },
    handleDirectRecharge(e) {
      // used to get order
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.directRechargeVisible = false;
          values.denominationId = this.voucherId;
          values.numberOfItems = values.Quantity;
          values.price = this.voucher.data.currentPrice;
          values.recipientMsisdn = this.directRechargeField;
          // delete values.Quantity;

          if (this.directRechargeField) {
            this.directRechargeFlag = true;
            if (this.checkPaymentMethod()) {
              this.setLoader(true);
              this.getAxios()
                  .post(
                      `${this.$session.get("baseURI")}sale/recharge`,
                      //body
                      values
                  )
                  .then((result) => {
                    this.setLoader(false);
                    console.log(result);
                    if (this.checkApiRes(result, values, 'sale/recharge', "post")) {
                      console.log("direct recharge voucher", result);
                      this.hideDirectRechargeModal();
                      this.success(
                          result.data.dialog.title,
                          result.data.dialog.message
                      );
                    }
                    // else {
                    //   // ok
                    //   console.log("direct recharge voucher", result);
                    //   this.hideDirectRechargeModal();
                    //   this.success(
                    //       result.data.dialog.title,
                    //       result.data.dialog.message
                    //   );
                    //   // this.voucherDetails = result.data.data.listOfSoldItems[0];
                    //   // this.showModal();
                    // }
                  });
            }
          } else {
            this.$message.error(this.voucher.data.topup.label);
          }

          console.log("Received values of form: ", values);
        }
      });
    },
    whishDirectRecharge(optionId) {
      // used to get order
      this.form.validateFields((err, values) => {
        if (!err) {
          values.denominationId = this.voucherId;
          values.numberOfItems = values.Quantity;
          values.price = this.voucher.data.currentPrice;
          values.recipientMsisdn = this.directRechargeField;
          values.optionId = optionId;
          // delete values.Quantity;

          if (this.directRechargeField) {
            this.setLoader(true);
            this.directRechargeFlag = false;
            this.getAxios()
                .post(
                    `${this.$session.get("baseURI")}sale/recharge`,
                    //body
                    values
                )
                .then((result) => {
                  this.setLoader(false);
                  console.log(result);

                  if (this.checkApiRes(result, values, 'sale/recharge', "post")) {
                    // ok
                    console.log("direct recharge voucher", result);
                    this.hideDirectRechargeModal();
                    this.success(
                        result.data.dialog.title,
                        result.data.dialog.message
                    );
                    // this.voucherDetails = result.data.data.listOfSoldItems[0];
                    // this.showModal();
                  }
                });
          } else {
            this.$message.error(this.voucher.data.topup.label);
          }
          console.log("Received values of form: ", values);
        }
      });
    },
    paymentByCCSuccess(result) {
      this.paymentOptionsVisible = false;
      let res = result.data;
      if (!res.dialog) {
        let itemSold = result.data.data.listOfSoldItems;
        this.voucherDetails = itemSold;
        this.showModal();
      } else {
        this.$message.error(`${res.dialog.title}\n ${res.dialog.message}`);
      }
    },
    whishGetOrder(optionId) {
      // used to get order
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("whish get order initiating ");
          values.denominationId = this.voucherId;
          values.numberOfItems = values.Quantity;
          values.price = this.voucher.data.currentPrice;
          values.optionId = optionId;
          delete values.Quantity;
          this.directRechargeFlag = false;
          this.setLoader(true);
          this.getAxios()
              .post(
                  `${this.$session.get("baseURI")}sale/voucher`,
                  //body
                  values
              )
              .then((result) => {
                this.setLoader(false);
                console.log(result);
                if (this.checkApiRes(result, values, "sale/voucher", "post")) {
                  // ok
                  console.log("whish sale voucher", result);
                  this.voucherDetails = result.data.data.listOfSoldItems;
                  this.showModal();
                }
              });
        }
      });
    },
    isEmpty(obj) {
      for (var o in obj) {
        if (o) {
          return false;
        }
      }
      return true;
    },
    checkPaymentMethod() {
      // the whole cc payment is here just check payment solution and the modal will do the rest

      // always check prices in voucher object and server.paymentOptions
      // if both are not null
      // if topleft menu (default_payment exists) && usertogglevalue is true do api aade
      // else

      // get intersect between prices and server.paymentoptions (paymentoptions.id == voucher.prices['id']) id is the key of the json
      // if empty do normal api
      //WHISHPAYMENT AND ANY OTHER else show payment options (type, show: label) prices: show price marching with paymentoptions when chosen send optionId with the body as the id of the paymentoptions selected
      // CREDIT CARD else show payment options (type, show: label) prices: show price marching with paymentoptions when chosen send optionId with the body as the id of the paymentoptions selected

      // if both or one of them null continue aade
      this.paymentOption = null;
      if (
          this.$session.get("paymentOptions") &&
          !this.isEmpty(this.voucher.data.prices)
      ) {
        if (
            this.$session.get("userToggleValue") == true &&
            this.$session.get("default_payment") == true
        ) {
          console.log("do normal api");
          // do normal api
          return true;
        } else {
          console.log("methods");
          // check payments option
          this.paymentOptionsVisible = true;
          this.showPaymentOptions = true;
          return false;
        }
      } else {
        console.log("normal api");
        // do normal api
        return true;
      }
    },
    logout() {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/session/deactivate`, {
            sessionId: this.$session.get("sessionId"),
          })
          .then((result) => {
            if (this.checkApiRes(result, null, "account/session/deactivate", "post")) {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            } else {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");
                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            }
          });
    },
    handleSubmit(e) {
      // used to get order
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values.denominationId = this.voucherId;
          values.numberOfItems = values.Quantity;
          values.price = this.voucher.data.currentPrice;
          delete values.Quantity;
          this.directRechargeFlag = false;
          if (this.checkPaymentMethod()) {
            this.setLoader(true);
            this.getAxios()
                .post(
                    `${this.$session.get("baseURI")}sale/voucher`,
                    //body
                    values
                )
                .then((result) => {
                  this.setLoader(false);
                  if (this.checkApiRes(result, values, "sale/voucher", "post")) {
                    // ok
                    console.log("sale voucher", result);
                    this.voucherDetails = result.data.data.listOfSoldItems;
                    this.showModal();
                  }
                });
          }
          console.log("Received values of form: ", values);
        }
      });
    },
  },
  created() {
    this.checkLocal();
    if (this.$route.params.sold) {
      let res = this.$route.params.sold.data;
      if (!res.dialog) {
        let itemSold = this.$route.params.sold.data.data.listOfSoldItems;
        console.log(itemSold);
        this.voucherDetails = itemSold;
        this.showModal();
      } else {
        alert(`${res.dialog.title}\n ${res.dialog.message}`);
      }
    }
    if (!this.$route.params.voucher) {
      //usefined
      this.voucher = this.$session.get("backup");
      this.voucherId = this.voucher.data.id;
    } else {
      // ok
      this.voucher = this.$route.params.voucher;
      this.voucherId = this.voucher.data.id;
      this.$session.set("backup", this.$route.params.voucher);
    }
    console.log("voucher: ", this.voucher);
    console.log("prices: ", this.voucher.data.prices);
  },
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  position: absolute;
  z-index: 999;
  padding: 20px;
  background: #fffcdf;
  left: 0;
}

.banner-protection {
  position: absolute;
  width: 100%;
  height: 100%;
  background: lightgray;
  left: 0;
  z-index: 998;
  opacity: 0.5;
}

.categories {
  .category {
    margin-bottom: 10px;

    .category-title {
      background: #e62946;
      color: white;
      border-top-right-radius: 45px;
      border-bottom-right-radius: 45px;
      padding: 1.25% 2% 0px 1%;
      // width: fit-content !important;
      max-width: fit-content;
      // min-width: fit-content;
      font-size: 60%;
    }

    .items {
      border-bottom: 1px solid black;

      a {
        text-decoration: none;
        color: black;
      }

      .item {
        margin-right: 10px !important;
        width: fit-content;

        img {
          padding: 5px;
          margin: auto;
          max-width: 80px;
        }
      }
    }
  }
}

img.voucher-result {
  width: inherit;
}

.card_custom {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  padding: 2%;
  height: 70px;
  margin-bottom: 10px;
}

.card_custom:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.pointer {
  cursor: pointer;
}
</style>
