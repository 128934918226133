<template>
  <div class="container">
    <div v-if="chooseCreditCard">
      <div
          style="cursor: pointer"
          class="row d-flex justify-content-center"
          @click="showaddCard = !showaddCard"
      >
        <a-icon type="plus-circle" v-if="!showaddCard"/>
        <a-icon type="minus-circle" v-if="showaddCard"/>
      </div>
      <CreditCardPortable v-if="showaddCard"/>
      <p @click="getAllCreditCards">{{ locale.refresh_cards }}</p>
      <div
          class="
          creditCard
          row
          ml-1
          mt-2
          mb-2
          card_custom
          d-flex
          align-items-center
          pointer
        "
          v-for="card in creditCardsObject"
          :key="card.creditCardId"
          @click="selectCard(card, apiBody)"
      >
        <div class="col-2">
          <img
              v-if="card.type.toLowerCase() == 'visa'"
              :src="require(`../assets/ic_visa.png`)"
              alt=""
          />
          <img
              v-else-if="card.type.toLowerCase() == 'mastercard'"
              :src="require(`../assets/ic_mastercard.png`)"
              alt=""
          />
          <img v-else src="#" :alt="card.type"/>
        </div>
        <div class="col-2 d-flex align-items-center"></div>
        <div class="col-8" style="text-align: start">
          <span>{{ card.labelName }}</span
          ><br/>
          <span>{{ card.number }}</span>
        </div>
      </div>
    </div>
    <div class="otp card" v-if="otpObject">
      <div id="lol"></div>
      <iframe
          style="display: none;"
          referrerpolicy=""
          allow="payment"
          ref="iframetest"
          src=""
          width="100%"
          frameborder="0"
          @load="continueCCPaymentAfter3DSecure($refs.iframetest)"
      ></iframe>
    </div>
    <a-modal
        v-model="visible"
        v-if="card"
        :title="card.securityCodeLabel"
        @ok="payByCard(card, apiBody)"
        :okText="card.securityCodeButton"
        okType="danger"
    >
      <input
          v-model="cvc"
          :placeholder="card.securityCodeHint"
          :type="card.securityCodeInputType"
          required
      />
    </a-modal>
    <Loading :loading="loader"/>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import CreditCardPortable from "./CreditCardPortable";
import firebase from "firebase";
import Loading from "./Loading.vue";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";
import {API_ENDPOINTS} from '../scripts/request'

// @ is an alias to /src
export default {
  name: "PaymentCC",
  components: {CreditCardPortable, Loading},
  props: {
    apiBody: Object,
  },
  data() {
    return {
      locale: null,
      loader: false,
      cvc: null,
      cvcPromptObject: null,
      card: null,

      visible: false,
      showaddCard: false,
      creditCardsObject: null,
      chooseCreditCard: false,
      otpObject: null,

      apiEndpoints: API_ENDPOINTS,
    };
  },
  methods: {
    continueCCPaymentAfter3DSecure(frame) {
      // here we listen to iframe change of source then we check if it's the same as our origin we continue with payment success
      //  thus we topup then check item type accordingly
      //  then emit the necessary values and then use in each component the handleOld function to continue normally
      //  and added in the handle old function  the paymentOptionsVisible= false in case the payment chosen is the iframe on eto close the modal when done
      alert(
          "Don't forget to ask which to comment between iframe and normal one"
      );
      console.log(
          "iframe src",
          frame.contentWindow.location.href,
          "my src: ",
          window.location.origin
      );
      if (window.location.origin + "/" == frame.contentWindow.location.href) {
        this.apiBody.fetchPrice = false;
        let values = this.apiBody;
        this.setLoader(true);
        this.getAxios()
            .post(
                `${this.$session.get("baseURI")}account/topup/creditcard`,
                values
            )
            .then((result) => {
              this.setLoader(false);
              if (this.checkApiRes(result, values, "account/topup/creditcard", "post")) {
                // dont forget to emit after success to close this modal and  show the success modal with the voucher stuff in it
                if (values.type == "voucher") {
                  //checking if there is the recipient phone it means that we should direct recharge
                  let apiEndpoint = "sale/voucher";
                  if (values.recipientMsisdn) {
                    apiEndpoint = "sale/recharge";
                    console.log("direct recharge values: ", values);
                  }
                  this.setLoader(true);
                  this.getAxios()
                      .post(
                          `${this.$session.get("baseURI")}${apiEndpoint}`,
                          //body
                          values
                      )
                      .then((result) => {
                        this.setLoader(false);
                        if (this.checkApiRes(result, values, apiEndpoint, "post")) {
                          console.log("success cc voucher", result);
                          this.$emit("paymentByCCSuccess", result);
                        } else {
                          this.$message.error(result.data.dialog.message);
                        }
                      });
                } else if (values.type == "carfax") {
                  console.log("carfax CC vals: ", values);
                  this.$emit("handleOldCarfax", values);
                } else if (values.type == "transfer") {
                  console.log("transfer CC vals: ", values);
                  this.$emit("handleOldTransfer", values);
                } else if (values.type == "parkmeterfine") {
                  console.log("parkmeterfine CC vals: ", values);
                  this.$emit("handleOldFine", values);
                } else if (values.type == "phone") {
                  console.log("phone CC vals: ", values);
                  this.$emit("handleOldPhone", values);
                } else if (values.type == "bill") {
                  console.log("bill CC vals: ", values);
                  this.$emit("handleOldBill", values);
                } else if (values.type == "qr") {
                  console.log("bill qr vals: ", values);
                  this.$emit("handleOldQR", values);
                } else if (values.type == "wholesale") {
                  console.log("wholesale vals: ", values);
                  this.$emit("handleOldWholeSale", values);
                }
              }
            });
      }
    },
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
    setLoader(show) {
      this.loader = show;
    },
    getAxios() {
      this.setLoader(true);
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    showConfirm(result) {
      let self = this;
      this.$confirm({
        content: result.data.data.dialog,
        okText: result.data.data.buttonYes,
        cancelText: result.data.data.buttonNo,
        okType: "danger",

        onOk() {
          self.chooseCreditCard = false;
          let iframe = self.$refs.iframetest;
          let iframdoc =
              iframe.contentDocument || iframe.contentWindow.document;
          iframdoc.body.innerHTML = result.data.data.urlData.html;
          iframdoc.body.addEventListener("afterLayout", function () {
            console.log("works");
          });
          document.getElementById("lol").innerHTML =
              result.data.data.urlData.html;
          self.$emit("options", false);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    toggleCVCprompt() {
      if (this.visible == false) {
        this.cvc = null;
      }
      this.visible = !this.visible;
    },
    getAllCreditCards() {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}credit-card/cards`, {
            optionId: this.apiBody.optionId,
          })
          .then((result) => {
            this.setLoader(false);
            if (this.checkApiRes(result, {optionId: this.apiBody.optionId,}, "credit-card/cards", "post")) {
              console.log("getAllCreditCrads: ", result);
              this.creditCardsObject = result.data.data;
              this.chooseCreditCard = true;
            }
          });
    },
    selectCard(card) {
      //used when clicked on the card
      this.card = card;
      console.log(card.securityCodeRequired);
      if (card.securityCodeRequired) {
        this.toggleCVCprompt();
        this.apiBody.creditCardSecurityCode = this.cvc;
      } else {
        this.payByCard(card, this.apiBody);
      }
    },
    payByCard(card, apiBody) {
      console.log("payByCard started");
      if (card.securityCodeRequired) {
        this.apiBody.creditCardSecurityCode = this.cvc;
        this.visible = false;
      }
      // this.toggleCVCprompt();
      apiBody.creditCardId = card.creditCardId;
      let type = apiBody.type;
      if (type == "voucher") {
        // after the backend redirects me to the main page we use the type to know if it is a topup or if it's a voucher etc...
        let apiEndpoint = "sale/voucher";
        if (apiBody.recipientMsisdn) {
          apiEndpoint = "sale/recharge";
        }
        console.log(apiBody);
        this.getAxios()
            .post(
                `${this.$session.get("baseURI")}${apiEndpoint}`,
                //body
                apiBody
            )
            .then((result) => {
              this.setLoader(false);

              if (this.checkApiRes(result, apiBody, apiEndpoint, "post")) {
                console.log("success", result);
                this.otpObject = result.data.data.urlData;
                apiBody.price = result.data.data.price;
                apiBody.urlDataId = result.data.data.urlData.id;
                apiBody.fetchPrice = false;
                this.$session.set(
                    `${result.data.data.urlData.callback}`,
                    apiBody
                );
                console.log(apiBody);
                this.showConfirm(result);
              } else {
                console.log("error", result);
              }
            });
      } else if (type == "carfax") {
        console.log("carfax CC: ", apiBody);
        this.submitCarfax(apiBody);
      } else if (type == "transfer") {
        console.log("transfer CC: ", apiBody);
        this.submitTransfer(apiBody);
      } else if (type == "parkmeterfine") {
        console.log("parkmeterfine CC: ", apiBody);
        this.submitParkmeterFine(apiBody);
      } else if (type == "phone") {
        console.log("phone CC: ", apiBody);
        this.submitPhone(apiBody);
      } else if (type == "bill") {
        console.log("bill CC: ", apiBody);
        this.submitBill(apiBody);
      } else if (type == "qr") {
        console.log("qr CC: ", apiBody);
        this.submitScanQr(apiBody);
      } else if (type == "wholesale") {
        console.log("wholesale values: ", apiBody);
        this.submitwholesale(apiBody);
      }
    },

    submitScanQr(apiBody) {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}sale/pay`, apiBody)
          .then((result) => {
            this.setLoader(false);

            if (this.checkApiRes(result, apiBody, "sale/pay", "post")) {
              // ok
              console.log("after cc qr", result);
              this.otpObject = result.data.data.urlData;
              apiBody.price = result.data.data.price;
              apiBody.urlDataId = result.data.data.urlData.id;
              apiBody.fetchPrice = false;
              this.$session.set(`${result.data.data.urlData.callback}`, apiBody);
              console.log(apiBody);
              this.showConfirm(result);
            }
          });
    },

    submitBill(apiBody) {
      if (apiBody.payAll) {
        // pay all
        this.getAxios()
            .post(`${this.$session.get("baseURI")}sale/bill/payment`, apiBody)
            .then((result) => {
              this.setLoader(false);

              if (this.checkApiRes(result, apiBody, "sale/bill/payment", "post")) {
                // ok
                console.log("after cc phone", result);
                this.otpObject = result.data.data.urlData;
                apiBody.price = result.data.data.price;
                apiBody.urlDataId = result.data.data.urlData.id;
                apiBody.fetchPrice = false;
                this.$session.set(
                    `${result.data.data.urlData.callback}`,
                    apiBody
                );
                console.log(apiBody);
                this.showConfirm(result);
              }
            });
      } else {
        // pay partial
        this.getAxios()
            .post(`${this.$session.get("baseURI")}sale/bill/payment`, apiBody)
            .then((result) => {
              this.setLoader(false);

              if (this.checkApiRes(result, apiBody, "sale/bill/payment", "post")) {
                // ok
                console.log("after cc phone", result);
                this.otpObject = result.data.data.urlData;
                apiBody.price = result.data.data.price;
                apiBody.urlDataId = result.data.data.urlData.id;
                apiBody.fetchPrice = false;
                this.$session.set(
                    `${result.data.data.urlData.callback}`,
                    apiBody
                );
                console.log(apiBody);
                this.showConfirm(result);
              }
            });
      }
    },

    submitPhone(apiBody) {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}sale/item/deliver`, apiBody)
          .then((result) => {
            this.setLoader(false);
            if (this.checkApiRes(result, apiBody, "sale/item/deliver", "post")) {
              // ok
              console.log("after cc phone", result);
              this.otpObject = result.data.data.urlData;
              apiBody.price = result.data.data.price;
              apiBody.urlDataId = result.data.data.urlData.id;
              apiBody.fetchPrice = false;
              this.$session.set(`${result.data.data.urlData.callback}`, apiBody);
              console.log(apiBody);
              this.showConfirm(result);
            }
          });
    },
    submitwholesale(apiBody) {
      this.getAxios()
          .post(this.apiEndpoints.wholeSaleCheckout, apiBody)
          .then((result) => {
            this.setLoader(false);
            if (this.checkApiRes(result, apiBody, this.apiEndpoints.wholeSaleEndpoint, "post")) {
              // ok
              console.log("after cc wholesale", result);
              this.otpObject = result.data.data.urlData;
              apiBody.price = result.data.data.price;
              apiBody.urlDataId = result.data.data.urlData.id;
              apiBody.fetchPrice = false;
              this.$session.set(`${result.data.data.urlData.callback}`, apiBody);
              console.log(apiBody);
              this.showConfirm(result);
            }
          });
    },

    submitParkmeterFine(apiBody) {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}sale/bill/payment`, apiBody)
          .then((result) => {
            this.setLoader(false);

            if (this.checkApiRes(result, apiBody, "sale/bill/payment", "post")) {
              // ok
              console.log("full CC parkmeter payment: ", result);
              this.otpObject = result.data.data.urlData;
              apiBody.price = result.data.data.price;
              apiBody.urlDataId = result.data.data.urlData.id;
              apiBody.fetchPrice = false;
              this.$session.set(`${result.data.data.urlData.callback}`, apiBody);
              console.log(apiBody);
              this.showConfirm(result);
            }
          });
    },

    submitTransfer(apiBody) {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}sale/transfer/airtime`, apiBody)
          .then((result) => {
            this.setLoader(false);

            console.log(result);
            if (this.checkApiRes(result, apiBody, "sale/transfer/airtime", "post")) {
              // ok
              console.log("transfer CC success", result);
              this.otpObject = result.data.data.urlData;
              apiBody.price = result.data.data.price;
              apiBody.urlDataId = result.data.data.urlData.id;
              apiBody.fetchPrice = false;
              this.$session.set(`${result.data.data.urlData.callback}`, apiBody);
              console.log(apiBody);
              this.showConfirm(result);
            }
          });
    },

    submitCarfax(apiBody) {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}sale/carfax/get`, apiBody)
          .then((result) => {
            this.setLoader(false);

            if (this.checkApiRes(result, apiBody, "sale/carfax/get", "post")) {
              // ok
              console.log("carfax CC get", result);
              this.otpObject = result.data.data.urlData;
              apiBody.price = result.data.data.price;
              apiBody.urlDataId = result.data.data.urlData.id;
              apiBody.fetchPrice = false;
              this.$session.set(`${result.data.data.urlData.callback}`, apiBody);
              this.showConfirm(result);
            }
          });
    },

    checkApiResponse(result) {
      if (result.headers.sessioncounter) {
        this.$session.set("sessionCounter", result.headers.sessioncounter);
      }
      if (!result.data.status) {
        // error
        this.$message.error(result.data.dialog.message);
        console.error("server switch error : ", result);
        if (result.data.code === "auth.session_expired") {
          // log user out
          this.logout();
        }
      } else {
        return true;
      }
      return false;
    },
    logout() {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/session/deactivate`, {
            sessionId: this.$session.get("sessionId"),
          })
          .then((result) => {
            this.setLoader(false);

            console.log(this.checkApiResponse(result));
            if (this.checkApiResponse(result)) {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            } else {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            }
          });
    },
  },
  created() {
    this.checkLocal();
    this.getAllCreditCards();
  },
};
</script>

<style lang="scss" scoped>
#lol {
  text-align: center;
  padding: 5%;
}

.card_custom {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  padding: 2%;
  height: 100px;
}

.card_custom:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.pointer {
  cursor: pointer;
}
</style>
